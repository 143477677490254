import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout/Layout";
import {
  stringifyQuery,
  parseQuery
} from "@/utils/query";
Vue.use(VueRouter);
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  /* 首页资源*/
  {
    path: "/",
    name: "index",
    component: Layout,
    redirect: "/index",
    children: [{
      path: "/index",
      name: "HomePage",
      component: () => import("@/views/home/index"),
    },
    {
      path: "/search",
      name: "SearchIndex",
      component: () => import("@/views/search/index"),
    },
    {
      path: "/presale/:rid",
      name: "PresaleIndex",
      component: () => import("@/views/presale/index"),
    },
    {
      path: "/presale",
      name: "PresaleIndexs",
      component: () => import("@/views/presale/index"),
    },
    {
      // path: "/product/:shipId/:shipDate",
      path: "/product/:shipId",
      name: "ProductIndex",
      component: () => import("@/views/product/index"),
    },
    {
      path: "/order/:schedulingRoomId",
      name: "OrderIndex",
      component: () => import("@/views/order/index"),
    },
    {
      path: "/ticket",
      name: "TicketIndex",
      component: () => import("@/views/ticket/index"),
    },
    {
      path: "/submit",
      name: "Submit",
      component: () => import("@/views/submit/index"),
    },
    {
      path: "/pay",
      name: "Pay",
      component: () => import("@/views/pay/index"),
    },
    {
      path: "/personal",
      name: "Personal",
      component: () => import("@/views/personal/index"),
    },
    {
      path: "/family",
      name: "Family",
      component: () => import("@/views/family/index"),
    },
    {
      path: "/exitOrder",
      name: "ExitOrder",
      component: () => import("@/views/order/extraOrder"),
    },
    {
      path: "/sys/:type?",
      name: "Config",
      component: () => import("@/views/sys"),
    },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // routes: routes,
  routes: [...routes],
  stringifyQuery, // 序列化query参数
  parseQuery, // 反序列化query参数
  scrollBehavior(to, from, savedPosition) {
    // 配置页面跳转后，默认显示顶部
    return {
      x: 0,
      y: 0
    };
  },
});
/* 重写router方法 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
export default router;